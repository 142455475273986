import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import TeamCard from "./TeamCard"
import SectionHeading from "./SectionHeading"

const Team = ({ about_us }) => {
  const members = about_us.team_members

  return (
    <Row className="mobile align-items-center justify-content-center mr-5 ml-5">
      <Col xs={12} sm={12} md={12} lg={12} xl={3}>
        <SectionHeading heading={about_us.team_heading} align="left" />
        <p>{about_us.team_body}</p>
        {/* <CTAs ctas={about_us.team_ctas} className='text-secondary' /> */}
      </Col>
      {members
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .map((member, i) => (
          <Col xs={6} sm={4} md={4} lg={3} xl={3}>
            <TeamCard member={member} i={i} />
          </Col>
        ))}
    </Row>
  )
}

export default Team
