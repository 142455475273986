import React, { useRef, useState } from "react"
import SocialLink from "../components/SocialLink"
import { isMobileOnly } from "react-device-detect"

import Image from "./image"
import TeamModal from "./TeamModal"
import { useInView } from "react-intersection-observer"

const TeamCard = ({ member, i }) => {
  const { ref, inView } = useInView()

  const [modalOpen, setModal] = useState(false)

  const toggleModal = active => {
    if (active) {
      setModal(true)
      document.body.classList.add("active-modal")
    } else {
      setModal(false)
      document.body.classList.remove("active-modal")
    }
  }

  return (
    <>
      <div
        role="button"
        tabIndex={0}
        className="teamCard mb-4"
        onClick={toggleModal.bind(this, true)}
        onKeyDown={toggleModal.bind(this, true)}
        ref={ref}
        style={{
          transform: !inView ? "translateY(1rem)" : "none",
          opacity: !inView ? 0 : 1,
          transition: `0.5s all ease-out ${(i / 4) * 0.2}s`,
        }}
      >
        <Image
          resizeWidth={400}
          src={member.image}
          className="img-fluid teamCard__image"
        />
        {!isMobileOnly ? (
          <div className="teamCard__overlay">
            <div className="teamCard__info">
              <h3 className="teamCard__name">{member.name}</h3>
              <em className="teamCard__position">{member.position}</em>
              {(member.linkedin || member.twitter) && (
                <ul className="teamCard__social">
                  {member.linkedin && (
                    <li>
                      <SocialLink
                        label="LinkedIn"
                        icon="linkedin"
                        url={`https://linkedin.com/in/${member.linkedin}`}
                      />
                    </li>
                  )}
                  {member.twitter && (
                    <li>
                      <SocialLink
                        label="Twitter"
                        icon="twitter"
                        url={`https://twitter.com/${member.twitter}`}
                      />
                    </li>
                  )}
                  {member.instagram && (
                    <li>
                      <SocialLink
                        label="Instagram"
                        icon="instagram"
                        url={`https://instagram.com/${member.instagram}`}
                      />
                    </li>
                  )}
                  {member.github && (
                    <li>
                      <SocialLink
                        label="Github"
                        icon="github"
                        url={`https://github.com/${member.github}`}
                      />
                    </li>
                  )}
                </ul>
              )}
              <p className="teamCard__excerpt">{member.excerpt}</p>
            </div>
          </div>
        ) : null}
      </div>
      <TeamModal
        member={member}
        active={modalOpen}
        onClick={toggleModal.bind(this, false)}
      />
    </>
  )
}

export default TeamCard
