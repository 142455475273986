import React, { useRef } from "react"
import { graphql } from "gatsby"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { Parallax } from "react-parallax"

import Hero from "../components/Hero"
import Layout from "../components/layout"
import CTAs from "../components/CTAs"
import SectionHeading from "../components/SectionHeading"
import NumberedList from "../components/NumberedList"
import Team from "../components/Team"
import Image from "../components/image"
import SEO from "../components/seo"
import { useInView } from "react-intersection-observer"

const IntroTextSection = ({ about_us }) => {
  const { ref, inView } = useInView()

  return (
    <Container as="section" ref={ref}>
      <Row>
        <Col>
          <Image
            src="/img/footer_logo.svg"
            width={50}
            height={46}
            className="mobile-logo d-block mx-auto mb-5"
            style={{
              transform: !inView ? "translateY(1rem)" : "none",
              opacity: !inView ? 0 : 1,
              transition: "0.5s all ease-out",
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <h2
            className="h4 text-center mb-4"
            style={{
              transform: !inView ? "translateY(1rem)" : "none",
              opacity: !inView ? 0 : 1,
              transition: "0.5s all ease-out 0.25s",
            }}
          >
            {about_us.mission_heading}
          </h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <p
            className="text-center lead"
            style={{
              transform: !inView ? "translateY(1rem)" : "none",
              opacity: !inView ? 0 : 1,
              transition: "0.5s all ease-out 0.5s",
            }}
          >
            {about_us.mission}
          </p>
        </Col>
      </Row>
    </Container>
  )
}

const OurPartnersSection = ({ about_us }) => {
  const { ref, inView } = useInView()

  return (
    <Container as="section" ref={ref}>
      <SectionHeading
        crown="Our Partners"
        className="mb-3 mb-lg-5"
        style={{
          transform: !inView ? "translateY(1rem)" : "none",
          opacity: !inView ? 0 : 1,
          transition: "0.5s all ease-out",
        }}
      />
      <Row className="align-items-center justify-content-center">
        {about_us.clients.map((client, i) => (
          <Col
            lg={3}
            xs={6}
            className="text-center pt-3"
            style={{
              transform: !inView ? "translateY(1rem)" : "none",
              opacity: !inView ? 0 : 1,
              transition: `0.25s all ease-out ${0.05 * i}s`,
            }}
          >
            <Image
              resizeWidth={300}
              src={client.logo}
              className="client-logo img-fluid px-2 mb-3 px-lg-5 mb-lg-0"
              style={{
                objectFit: "contain",
              }}
            />
          </Col>
        ))}
      </Row>
    </Container>
  )
}

const AboutUsPage = ({ data, location }) => {
  const about_us = data.aboutUs.edges[0].node

  return (
    <Layout
      hireUs={false}
      hero={
        <Hero
          heading={about_us.hero_heading}
          subhead={about_us.hero_subhead}
          image={about_us.hero_background}
        />
      }
    >
      <SEO title="About Us" path={location.pathname} />
      <IntroTextSection about_us={about_us} />
      <Container fluid={true} className="p-0">
        <img src={about_us.group_photo} style={{ width: "100%" }} />
      </Container>
      <OurPartnersSection about_us={about_us} />
      <Container as="section" fluid={true}>
        <Team about_us={about_us} />
      </Container>
      <Container as="section">
        <Row>
          <Col lg="6" sm="12">
            <SectionHeading
              heading={about_us.culture_heading}
              subhead={about_us.culture_subhead}
              align="left"
            />
            <p>{about_us.culture_body}</p>
          </Col>
          <Col lg="6" sm="12">
            <NumberedList items={about_us.culture_points} />
          </Col>
        </Row>
      </Container>
      <Container as="section" fluid={true} className="text-center bg-gray">
        <Row>
          <Col lg={{ span: 8, offset: 2 }}>
            <SectionHeading
              crown={"Careers"}
              heading={about_us.careers_heading}
            />
            <p>{about_us.careers_body}</p>
            <CTAs ctas={about_us.careers_ctas} className="mb-3 mt-md-5" />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  {
    aboutUs: allButterPage(filter: { slug: { eq: "about-us" } }) {
      edges {
        node {
          hero_heading
          hero_subhead
          hero_background
          mission_heading
          mission
          group_photo
          clients {
            name
            logo
          }
          team_heading
          team_body
          team_ctas {
            label
            url
            icon
            type
          }
          team_members {
            image
            name
            position
            excerpt
            email
            linkedin
            twitter
            github
            instagram
          }
          culture_heading
          culture_subhead
          culture_body
          culture_points {
            heading
            body
          }
          careers_heading
          careers_body
          careers_ctas {
            label
            url
            icon
            type
          }
        }
      }
    }
  }
`

export default AboutUsPage
