import React from "react"

import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import Image from "./image"
import SocialLink from "../components/SocialLink"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { library } from "@fortawesome/fontawesome-svg-core"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
library.add(faTimes)

const TeamModal = ({ member, active, onClick }) => {
  return (
    <div
      className="teamModal__wrapper"
      style={{
        display: active ? "flex" : "none",
      }}
    >
      <div className="teamModal__content">
        <p className="close-btn">
          <button className="modal-exit" onClick={onClick}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </p>
        <Row>
          <Col xs={12} md={6}>
            <Image
              resizeWidth={400}
              src={member.image}
              className="img-fluid"
              alt={member.name}
            />
          </Col>
          <Col xs={12} md={6}>
            <h3>{member.name}</h3>
            <strong>{member.position}</strong>
            <p>{member.excerpt}</p>
            <p>
              <a href={`mailto:${member.email}`}>{member.email}</a>
            </p>

            {(member.linkedin || member.twitter) && (
              <ul className="teamCard__social">
                {member.linkedin && (
                  <li>
                    <SocialLink
                      label="LinkedIn"
                      icon="linkedin"
                      url={`https://linkedin.com/in/${member.linkedin}`}
                    />
                  </li>
                )}
                {member.twitter && (
                  <li>
                    <SocialLink
                      label="Twitter"
                      icon="twitter"
                      url={`https://twitter.com/${member.twitter}`}
                    />
                  </li>
                )}
              </ul>
            )}
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default TeamModal
